import React from "react"

class ContactForm extends React.Component {
  render() {
    const {
      submitted,
      emailIsValid,
      onChange,
      onSubmit,
      className,
      refProp,
    } = this.props

    return (
      <section
        id="contact"
        className={`contact ${className ? className : ""}`}
        ref={refProp}
      >
        <h2>Free 45 min consultation call</h2>
        <p>
          Starting a new project? Leave your email and I would love to see if I
          can help you. I will reach out to schedule the call.
        </p>
        <form onSubmit={onSubmit}>
          <input
            disabled={submitted}
            className={`${emailIsValid ? "" : "error"}`}
            onChange={onChange}
            type="email"
            name="email"
            autoComplete="email"
            placeholder="Your Email"
            ref={node => (this.emailNode = node)}
          />
          <p hidden={emailIsValid ? true : false}>
            Please make sure your email address is correct
          </p>
          {submitted ? (
            <button disabled className="success">
              Great! Request sent{" "}
              <span aria-label="success icon" role="img">
                🎉
              </span>
            </button>
          ) : (
            <button disabled={!emailIsValid}>Submit</button>
          )}
        </form>
      </section>
    )
  }
}

export default ContactForm
