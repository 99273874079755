/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, createRef } from "react"
import PropTypes from "prop-types"
import "./layout.css"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import logo from "../images/logo.svg"
import hamburger from "../images/hamburger.svg"
import validator from "email-validator"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ContactForm from "../components/contact-form"
import PageContext from "../context"

const Menu = ({ isOpen, handleToggle, handleCTA }) => (
  <nav>
    <header>
      <div>
        <img className="logo" src={logo} alt="Nomadic Logo" />
      </div>
      <ul className="inline">
        <MenuItems handleCTA={handleCTA} />
      </ul>
      <img
        onClick={() => handleToggle()}
        className="hamburger"
        src={hamburger}
        alt="Open menu"
      />
    </header>
    <ul className={`dropdown ${isOpen ? "isOpen" : null}`}>
      <MenuItems handleCTA={handleCTA} />
    </ul>
  </nav>
)

const MenuItems = ({ handleCTA }) => (
  <>
    <li>
      <Link activeClassName="active" to="/">
        🚀 Launch a product
      </Link>
    </li>
    <li>
      <Link activeClassName="active" to="/fast">
        ⚡️ Solve a problem
      </Link>
    </li>
    <li>
      <button onClick={e => handleCTA(e)}>Book a Call</button>
    </li>
  </>
)

const Layout = ({ children, className }) => {
  const contactRef = createRef()

  const [menuOpen, setMenuOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [emailIsValid, setEmailIsValid] = useState(true)
  const [submitted, setSubmitted] = useState(false)

  const validateEmail = () => {
    const rule = email.length < 3 ? true : validator.validate(email)

    setEmailIsValid(rule)
  }
  const updateValues = e => {
    e.preventDefault()
    setEmail(e.target.value)
    validateEmail()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (emailIsValid) {
      const result = await addToMailchimp(email)
      console.log(result)
      setSubmitted(true)
    } else {
    }
  }
  const scrollToContact = e => {
    e.preventDefault()
    window.scrollTo(0, contactRef.current.offsetTop)
  }

  const toggleMenu = () => setMenuOpen(!menuOpen)

  // const childrenWithProps = React.cloneElement(children, {
  //   handleCTA: scrollToContact,
  // })

  return (
    <>
      <Helmet>
        <title>nomadic.</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,400;0,500;0,600;0,700;1,300&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <PageContext.Provider value={{scrollToContact}}>
        <div className={className}>
          <Menu
            handleCTA={scrollToContact}
            isOpen={menuOpen}
            handleToggle={toggleMenu}
          />
          {children}
          <ContactForm
            submitted={submitted}
            onChange={updateValues}
            onSubmit={handleSubmit}
            emailIsValid={emailIsValid}
            className="contact"
            refProp={contactRef}
          />
          <footer>
            © 2020, Built by{" "}
            <a href="https://www.benita.me" target="_blank" rel="noreferrer">
              Benita
            </a>{" "}
            and{" "}
            <a href="https://www.primak.co" target="_blank" rel="noreferrer">
              Primak
            </a>
          </footer>
        </div>
      </PageContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
